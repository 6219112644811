input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

* {
  transition: all 26ms ease-in-out;
}

body {
  height: 100%;
  min-width: 100vw;
  overflow: hidden;
  min-height: 100vh;
  text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

input,
input:focus,
input:active,
button,
button:focus,
button:active,
textarea,
select,
button[type="submit"],
.btn,
.btn:focus,
.btn:active,
.alert,
.modal-content {
  box-shadow: none !important;
  border-radius: 0.1rem !important;
}

::-webkit-scrollbar {
  width: 0.4rem;
}

::-webkit-scrollbar-track,
::-webkit-scrollbar-thumb {
  border-radius: 0.5rem;
  -webkit-box-shadow: inset 0 0 6px var(--bs-secondary);
}

main {
  display: flex;
  flex: 1 1 auto;
  background: var(--bs-white);
}

.letter-spaced {
  letter-spacing: 0.15rem;
}

.sidebar {
  flex: 1 0 auto;
  min-width: 196px;
  max-height: 100%;
  overflow-y: auto;
  max-width: fit-content;
  background: var(--bs-secondary);
  transition: all 260ms ease-in-out;
}

.hide-sidebar {
  margin-left: -196px;
}

.sidebar-nav {
  color: var(--bs-light);
}

.sidebar-nav a.active {
  border: 1px solid var(--bs-primary) !important;
  border-left-width: 0.3em !important;
  border-right: none !important;
  color: var(--bs-secondary) !important;
  background-color: var(--bs-white) !important;
}

.contents {
  max-width: 100%;
  overflow-x: hidden;
}

.table-responsive {
  max-height: 78vh;
  max-width: 100% !important;
}

.table-responsive thead {
  top: 0;
  position: sticky;
  background: var(--bs-white) !important;
}

.hover-underline {
  text-decoration: none !important;
}

.hover-underline:hover {
  text-decoration: underline !important;
}

.text-justify {
  text-align: justify;
}

.login-bg {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url("../images/backgrounds/login_bg.png");
}

.image {
  max-width: 100%;
  object-fit: contain;
  aspect-ratio: 16 / 11;
}

.image-label {
  top: 0;
  left: 0;
  opacity: 0;
  display: flex;
  min-width: 100%;
  cursor: pointer;
  min-height: 100%;
  position: absolute;
  align-items: center;
  color: var(--bs-light);
  justify-content: center;
  transition: all 300ms ease-out;
}

.image-label:hover {
  opacity: 1;
  background-color: #111b;
}

.modal.show .modal-dialog {
  margin: 0;
  display: flex;
  min-width: 100vw;
  max-width: 100vw;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
}

.modal-content {
  width: var(--bs-modal-width) !important;
}

.water-mark {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  line-height: 6.5rem !important;
  opacity: 0.06;
  font-weight: 900 !important;
}

.dashboard {
  max-height: 90vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 0.5rem;
}

@media (max-width: 576px) {
  .sidebar {
    margin-left: -196px;
  }

  .hide-sidebar {
    margin-left: 0;
  }
}

@media print {
  table {
    border: 1px solid var(--bs-secondary);
  }

  th:last-child,
  td:last-child {
    display: none;
  }

  .table-responsive {
    max-height: fit-content !important;
  }
}
